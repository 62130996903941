import { useQuery } from 'react-query';

export const savedProductIdListQueryKeys = {
  savedProduct: 'savedProductIdListQueryKeys.savedProduct',
  isLogin: 'savedProductIdListQueryKeys.isLogin',
};
export function useLoginQuery() {
  return useQuery<boolean>([savedProductIdListQueryKeys.isLogin], loginFetcher, {
    refetchOnMount: true,
    refetchOnWindowFocus: true,
    staleTime: 0,
    cacheTime: 0,
  });
}

function loginFetcher() {
  const uuid = typeof localStorage !== 'undefined' ? localStorage.getItem('uuid') : null;
  return Boolean(uuid);
}
